import React, {useEffect, useState, useContext} from 'react'
import {  useParams } from 'react-router-dom';
import { PageFull, PageSixty, PageTitle } from 'monica-alexandria'
import { getReport } from '../../services/api/AutomatedReport';
import Report_Data from '../../sources/meta/pages/creator/components/report/Report_Data';
import { format_date } from '../../helpers/DateFormatter';
import { ReportStyleContext } from '../../sources/meta/context/ReportStyleContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Report = styled(PageFull)`
    padding: 4rem 2rem;
`

const ReportContainer = styled.div`
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
`

export default function REPORT() {
    const {t} = useTranslation();

    const [reportId, setReportId] = useState(useParams().id);
    const [report, setReport] = useState();
    const {reportStyle, setReportStyle} = useContext(ReportStyleContext);
    const [hidePage, setHidePage] = useState(false);


    useEffect(() => {
        getReport(reportId)
        .then((res) => {
            if(res.data.code === 200) {
                const fetchedReport = res?.data?.data?.report;
                console.log(fetchedReport);
                setReport(fetchedReport);
                if(fetchedReport.template.presetStyleId)
                    setReportStyle(fetchedReport.template.presetStyleId)
            }
            else {
                setHidePage(true);
            }
        })
        .catch(err => {
            console.error(err);
        });
    }, []);


    return (
        <Report>
            {hidePage ? null :
                <ReportContainer>
                    <Report_Data 
                        viewMode={true}
                        dateFrom={format_date(report?.dateFrom, report?.dateFormat)}
                        dateTo={format_date(report?.dateTo, report?.dateFormat)}
                        title={report?.title}
                        sections={report?.sections}
                        currency={report?.currency}
                        brand={report?.template?.presetStyleId?.brand ? report?.template?.presetStyleId?.brand : "Doitforme"}
                    /> 
                </ReportContainer>
            }
        </Report>
    )
}
