import { Builder_, Input_, Slider_, Textarea_ , Upload_Media} from 'monica-alexandria';
import React, { useEffect, useState} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';

export default function Edit_Element_Comments(props) {
    console.log(props);

    const { t } = useTranslation();
    const DELAY_IN_MS = 200;

    const [messagesTitle, setMessagesTitle] = useState({
        successes: [],
        errors: [],
        helpers: [],
    });

    const [messagesText, setMessagesText] = useState({
        helpers: [],
        errors: [],
        successes: [],
    });

    const changeMessages = (text, source) => {
        if(source === 'title') {
            if(!text) {
                setMessagesTitle({helpers: [(t("Fill out this field"))], successes: '', errors: ''})
            }
            else {
                setMessagesTitle({helpers: '', successes: [(t('Fill out this field'))], errors: ''})
            }
        }
        else {
            if(!text) {
                setMessagesText({helpers: [(t("Fill out this field"))], successes: '', errors: ''})
            }
            else {
                setMessagesText({helpers: '', successes: [(t('Fill out this field'))], errors: ''})
            }
        }
    }

    // the below functions "lag" the actual function execution by half a second, eliminating unwanted re-renders
    const debounced_changeMessages= useDebouncedCallback(
        changeMessages, 500
    );

    const debounced_onTitleEdit= useDebouncedCallback(
        (e) => {
            props.onTitleEdit(e);
        }, DELAY_IN_MS
    );

    const debounced_onTextEdit= useDebouncedCallback(
        (e) => {
            props.onTextEdit(e);
        }, DELAY_IN_MS
    );

    const onDone = () => {
        if(!props.comment?.title) {
            setMessagesTitle({helpers: '', errors: [(t('Fill out this field'))], successes: ''})
        }
        if(!props.comment?.text)
            setMessagesText({helpers: '', errors: [(t('Fill out this field'))], successes: ''})
        if(props.comment?.title && props.comment?.text)
            props.onClick();
    }

    return (
        <Slider_ title={t('Give your comment a title')} onClick={onDone} text={t("Done")}>
            <Builder_ title={t('Comment title')}>
                <Input_ 
                    required={true}
                    placeholder={t('Title')} 
                    defaultValue={props.comment?.title} 
                    onChange={(e) => {
                        debounced_changeMessages(e.target.value, 'title')
                        debounced_onTitleEdit(e)}
                    }
                    helpers={messagesTitle?.helpers}
                    successes={messagesTitle?.successes}
                    errors={messagesTitle?.errors}
                    />
            </Builder_>
            <Builder_ title={t('Fill in your comment below')}>
                <Textarea_ 
                    placeholder={t('Comment')} 
                    onChange={(e) => {
                        debounced_changeMessages(e.target.value, 'text');
                        debounced_onTextEdit(e);   
                    }}
                    helpers={messagesText?.helpers}
                    successes={messagesText?.successes}
                    errors={messagesText?.errors}
                    required={true}
                    defaultValue={props.comment?.text}
                />
            </Builder_>
        </Slider_>
        
    );
}

