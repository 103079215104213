import { Builder_, CardsSixty, Checkbox_, Dropdown_, Input_, Section_Title, Slider_ } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

const CheckboxWrapper = styled.div``;

export default function Edit_Row(props) {

    const { t } = useTranslation();
  
    const blockSections = ['overview'];
    const blockRows = ['demographics', 'graphical'];

    const DELAY_IN_MS = 200;

    const debounced_onEditName = useDebouncedCallback(
        props.onEditName, DELAY_IN_MS
    );

    // There is not variable in the row for the variant so we decide its value by looking the variants of the elements
    const [variant, setVarinant] = useState(false);

    function getRowVariant(list) {
        if (list.length === 0) return false;
    
        // Assume the first element's variant is the one to compare against
        const firstVariant = list[0].variant;
    
        // Check if all elements have the same variant
        for (let i = 1; i < list.length; i++) {
            if (list[i].variant !== firstVariant) {
                return false;
            }
        }
    
        return firstVariant;
    }

    useEffect(() => {
        setVarinant(getRowVariant(props.row.elements));
    
    }, []);

    console.log(variant);

    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Edit row')}>
            <Builder_ title={t('Edit the title of the row')}>
                <Input_ placeholder={""} defaultValue={props.row?.rowTitle} onChange={(e) => debounced_onEditName(e.target.value)} />
            </Builder_>
            {props.rowType !== 'demographics' ?
                <Builder_ title={t('Edit the style of the indicator')}>
                    <Dropdown_>
                        <select value={variant} onChange={(e) => { props.onUpdateColor(e.target.value); setVarinant(e.target.value);} }>
                            <option value="main">{t('Primary')}</option>
                            <option value="focus">{t('Secondary')}</option>
                            <option value="mainInverted">{t('Primary inverted')}</option>
                            <option value="focusInverted">{t('Secondary inverted')}</option>
                            <option value="simple">{t('Simple')}</option>
                        </select>
                    </Dropdown_>
                </Builder_>
                :
                null}
            {!blockRows.includes(props.rowType) && !blockSections.includes(props.sectionType) ? (
                <CheckboxWrapper>
                    <Section_Title text={t('Options')} />
                    <CardsSixty>
                        <Checkbox_ text="Display compared values" 
                            checked={props?.row?.isCompared} 
                            onChange={() => props.toggleCompareOption(props?.row?.isCompared ? 'hide' : 'show')} 
                        />
                    </CardsSixty>
                </CheckboxWrapper>
            ) : null}
        </Slider_>
    );
}
